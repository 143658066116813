import React, { useState } from 'react'
import * as styles from './tab.module.scss'

export interface TabHeadItem {
  label: string
}

interface TabHeadProps {
  data: TabHeadItem[]
  onTabChange?(index: number, data?: TabHeadItem): void
}

export default function TabHead(props: TabHeadProps) {
  const { data, onTabChange } = props

  const [current, setCurrent] = useState<number>(0)

  const handleChange: TabHeadProps['onTabChange'] = (index, data) => {
    setCurrent(index)
    onTabChange && onTabChange(index, data)
  }

  return (
    <div className={styles.tabHeadButton}>
      {data?.length > 0 &&
        data.map((d, index) => (
          <button
            key={`th-${index}`}
            className={`tabButton ${index === current ? styles.active : ''}`}
            onClick={() => handleChange(index, d)}
          >
            {d.label}
          </button>
        ))}
    </div>
  )
}

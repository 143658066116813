import React, { useState } from 'react'
import replaceHife from '../../../utils/replaceHife'
import TabHead from '../../Core/Tab/TabHead'
import Text from '../../Core/Text'
import * as styles from './blockServiceIntro.module.scss'

interface BlockServiceIntroProps {
  data: {
    titleColivers: string
    descriptionColivers: string
    titleExternal: string
    descriptionExternal: string
    enableColiversSwitch: string
  }
  onFilter?(type: string): void
}

export default function BlockServiceIntro(props: BlockServiceIntroProps) {
  const {
    data: {
      titleColivers,
      descriptionColivers,
      titleExternal,
      descriptionExternal,
      enableColiversSwitch,
    },
    onFilter,
  } = props

  const [current, setCurrent] = useState(0)

  const handleTabChange = (index: number) => {
    setCurrent(index)
    onFilter && onFilter(['Colivers', 'External'][index])
  }

  return (
    <section className={styles.blockIntro}>
      <div className="container">
        {enableColiversSwitch && (
          <div className={`flex justify-center ${styles.blockIntroHead}`}>
            <TabHead
              data={[
                {
                  label: titleColivers,
                },
                {
                  label: titleExternal,
                },
              ]}
              onTabChange={handleTabChange}
            />
          </div>
        )}
        <div className={styles.blockIntroContent}>
          {[descriptionColivers, descriptionExternal].map(
            (d, index) =>
              current === index && (
                <Text
                  tag="p"
                  as="subtitleH1"
                  key={`ds-${index}`}
                  dangerouslySetInnerHTML={{ __html: replaceHife(d) }}
                ></Text>
              )
          )}
        </div>
      </div>
    </section>
  )
}

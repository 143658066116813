import React from 'react'
import replaceHife from '../../../utils/replaceHife'
import Text from '../../Core/Text'
import * as styles from './blockMarquee.module.scss'

interface BlockServiceMarqueeProps {
  data: {
    items: Array<{
      content: {
        title: string
      }
    }>
  }
}

export default function BlockServiceMarquee(props: BlockServiceMarqueeProps) {
  const {
    data: { items },
  } = props

  return (
    <section className={styles.blockMarqueeSection}>
      {[1, 2].map((item, index) => (
        <div
          className={`${styles.blockMarquee} ${
            item % 2 === 0 ? styles.blockMarqueeReverse : ''
          }`}
          key={`mw-${index}`}
        >
          <ul className={styles.blockMarqueeList}>
            {[...items, ...items, ...items, ...items]?.map((item, index) => {
              const isOdd = index % 2 === 0

              return (
                <Text
                  tag="li"
                  as="h4"
                  key={`mq-${index}`}
                  dangerouslySetInnerHTML={{
                    __html: `<span class="${isOdd ? 'gray' : ''}">${
                      isOdd ? '✹' : '✦'
                    }</span> ${replaceHife(item.content.title)}`,
                  }}
                />
              )
            })}
          </ul>
        </div>
      ))}
    </section>
  )
}

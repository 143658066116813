import React, { ReactNode, useEffect, useState } from 'react'
import Layout from '../components/layout'
import Hero from '../components/Shared/Hero'
import Event from '../components/Shared/Event'

import { graphql } from 'gatsby'

import BlockService from '../components/Shared/BlockService'
import BlockServiceIntro from '../components/Shared/BlockServiceIntro'
import BlockServiceMarquee from '../components/Shared/BlockServiceMarquee'
import BlockServiceSpace from '../components/Shared/BlockServiceSpace'
import * as styles from './styles/service.module.scss'
import DecoBigIllus from '../components/Illus/DecoBigIllus'
import BlockSeoText from '../components/Shared/BlockSeoText'
import Breadcrumb from '../components/Core/Breadcrumb'
import { useLocation } from '@reach/router'
import { head } from '../components/head'
import usePageView from '../hooks/usePageView'
import Modal from '../components/Core/Modal'
import ModalGeneral from '../components/Shared/ModalGeneralContent'

type DisplayMode = 'both' | 'Colivers' | 'External'

export const Head = head('umbracoServicesPage')

export default function Home(props: { data: any }) {
  const { data } = props
  const page = data.umbracoServicesPage
  const pageTitle = page?.metaTitle
  const popinGeneralVisible = page?.popinGeneralVisible
    ? page?.metaTitle
    : page?.title
    ? page?.title.replace(/\n/g, '')
    : 'Services'
  const [showGeneral, setShowGeneral] = useState<boolean>(false)
  const [currentService, setCurrentService] = useState<DisplayMode>('Colivers')

  const location = useLocation()

  const handleCurrentService = (serviceFor: DisplayMode) => {
    setCurrentService(serviceFor)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    if (typeof window !== 'undefined' && window.document) {
      usePageView(pageTitle, window.location.pathname)

      if (
        popinGeneralVisible &&
        !sessionStorage.getItem('service_' + page.id + '_already_shown')
      ) {
        setTimeout(() => {
          setShowGeneral(true)
        }, 2000)
      }
    }
  }, [])

  const hideModal = () => {
    setShowGeneral(false)
    sessionStorage.setItem('service_' + page.id + '_already_shown', 'true')
  }

  return (
    <>
      <Layout className={styles.serviceWrap}>
        <Hero
          title={page && page.title}
          image={page.imageCover}
          image-mobile={page.imageCoverMobile}
          video={page.videoCover}
          vimeo={page.vimeo}
          isBottom
        >
          <DecoBigIllus className="deco" />
        </Hero>
        <BlockServiceIntro
          data={{
            titleColivers: page.titleColivers,
            descriptionColivers: page.descriptionColivers,
            titleExternal: page.titleExternal,
            descriptionExternal: page.descriptionExternal,
            enableColiversSwitch: page.enableColiversSwitch,
          }}
          onFilter={handleCurrentService}
        />
        {page &&
          page.items &&
          page.items.map((homeBlock: any, index: number) => {
            if (homeBlock.content) {
              const blockContent = homeBlock.content

              const shouldDisplayIt = (content: ReactNode) => {
                return (
                  (blockContent.blockDisplay === 'Both' ||
                    currentService === blockContent.blockDisplay) &&
                  content
                )
              }

              switch (blockContent.contentTypeAlias) {
                case 'blockService':
                  return shouldDisplayIt(
                    <BlockService
                      uniqId={`hs-${index}`}
                      data={blockContent}
                      key={`home-${index}`}
                    />
                  )
                case 'blockMarquee':
                  return shouldDisplayIt(
                    <BlockServiceMarquee
                      data={blockContent}
                      key={`home-${index}`}
                    />
                  )
                case 'blockBookSpace':
                  return (
                    <BlockServiceSpace
                      data={blockContent}
                      key={`home-${index}`}
                    />
                  )
                case 'blockNewEvents':
                  return <Event data={blockContent} key={`home-${index}`} />
                default:
                  return
              }
            }

            return <></>
          })}

        <Breadcrumb
          data={[
            {
              title: page.title,
              link: location.pathname,
              id: '' + location.pathname,
            },
          ]}
        />
        {page.bottomSEOText && <BlockSeoText content={page.bottomSEOText} />}

        <div id="modal-single-residence">
          { popinGeneralVisible && page.popinToDisplay && (
            <Modal
              data={page.popinToDisplay}
              visible={showGeneral}
              onClose={hideModal}
              width={'1018px'}
              secondaryBg="FFFFFF"
              size="lg"
              general
              darkClose
            >
              <ModalGeneral data={page.popinToDisplay} />
            </Modal>
          )}
        </div>
      </Layout>
    </>
  )
}

export const query = graphql`
  query MyQuery($lang: String!) {
    umbracoServicesPage(lang: { eq: $lang }) {
      id
      name
      title
      pageTemplate
      metaTitle
      metaDescription
      metaFollow
      metaIndex
      twitterCard {
        id
        url
      }
      facebookCard {
        id
        url
      }
      _url
      _urls {
        fr
        en_us
      }
      imageCover {
        childImageSharp {
          id
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      imageCoverMobile {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      videoCover {
        src
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      vimeo
      titleColivers
      descriptionColivers
      titleExternal
      descriptionExternal
      enableColiversSwitch
      bottomSEOText
      popinGeneralVisible
      popinToDisplay { 
        _id
        name
        popinType
        subtitle
        stayTitle
        staySubtitle
        isActive
        popinGTMEventName
        cta {
          name
          target
          type
          url
        }
        _urls {
          en_us
          fr
        }
        colorCombination
        contactPhoneNumber
        contentTypeAlias
        title
        _url
      }
      items {
        content {
          layout
          blockDisplay
          colorCombination
          contentTypeAlias
          title
          subtitle
          spacesList {
            content {
              thumbnail {
                src
                gatsbyImageData(width: 680, quality: 90)
              }
              mewsId
              subtitle
              contentTypeAlias
              title
              linkSpaceItem {
                url
                name
              }
            }
          }
          slides {
            content {
              legend
              image {
                src
                gatsbyImageData
              }
              imageMobile {
                src
                gatsbyImageData
              }
              backgroundVimeoId
              contentTypeAlias
            }
          }
          eventsList {
            title
            subtitle
            bg
            thumbnail {
              media {
                alt
                _url
              }
              gatsbyImageData(width: 880, quality: 90)
            }
            backgroundVimeoId
            category {
              color
              title
            }
            price
          }
          link {
            url
            name
            type
          }
          linkEvent
          linkBookSpace {
            url
            type
            name
          }
          linkBookSpaceEvent
          button {
            name
            type
            url
          }
          items {
            content {
              contentTypeAlias
              title
            }
          }
        }
      }
    }
    allPageIntl {
      edges {
        node {
          name
          uri
          alternates {
            name
            uri
            lang
          }
        }
      }
    }
  }
`
